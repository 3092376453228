import React from "react";
import PropTypes from "prop-types";
// import MiscSocialSmall from "./../icons/MiscSocialSmall";
import ImagesCarousel from "./../ImagesCarousel";
import { H5SmallMedium, H2BigStrong, H2Big } from "./../Typography";
import { ArticleHeroImageDiv } from "./elements";

const ArticleHeroImage = ({
  topline,
  secondline,
  titleImage,
  titleText,
  subTitleText,
  color,
  useCarousel,
  carouselImages,
  showDots,
  imagesAreUrls,
  imagesAreClickable
}) => (
  <ArticleHeroImageDiv color={color || "var(--red)"} useCarousel>
    {/*<MiscSocialSmall color={color ? color : "var(--red)"} />*/}
    <H5SmallMedium style={{ color: "var(--purple)" }}>{topline}</H5SmallMedium>
    <H5SmallMedium style={{ color: "var(--white)" }}>
      {secondline}
    </H5SmallMedium>
    <div>
      {useCarousel ? (
        <ImagesCarousel
          images={carouselImages}
          controlColor={"var(--primary)"}
          showDots={showDots}
          imagesAreUrls={imagesAreUrls}
          imagesAreClickable={imagesAreClickable}
        />
      ) : (
        titleImage
      )}
    </div>
    <div>
      <H2BigStrong>{titleText}</H2BigStrong>
      <H2Big style={{ color: "var(--white)" }}>{subTitleText}</H2Big>
    </div>
  </ArticleHeroImageDiv>
);

export default ArticleHeroImage;

ArticleHeroImage.propTypes = {
  topline: PropTypes.string,
  titleImage: PropTypes.element,
  titleText: PropTypes.string,
  subTitleText: PropTypes.string,
  isDark: PropTypes.bool,
  showDots: PropTypes.bool,
};

ArticleHeroImage.defaultProps = {
  isDark: false,
  showDots: true,
};
