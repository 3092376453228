import React from "react";
import PropTypes from "prop-types";
// import MiscSocialSmall from "./../icons/MiscSocialSmall";
import { AboutSmallButton } from "./../Buttons";
import { H5SmallStrong, H5Big } from "./../Typography";
import { CommunityAboutDiv } from "./elements";

const CommunityAbout = ({
  communityMemberContactDetails,
  communityMemberDescription,
  communityMemberExtendedDescription,
  communityMemberEmailAddress,
  communityMemberLocation,
  communityMemberOpeningHours,
  communityMemberWebsite,
  communityButtonText,
  communityButtonLink,
  goToMap,
}) => {
  return (
    <CommunityAboutDiv>
      <div className="description">
        <div dangerouslySetInnerHTML={{ __html: communityMemberDescription }} />
        <div
          dangerouslySetInnerHTML={{
            __html: communityMemberExtendedDescription,
          }}
        />
      </div>
      <div>
        <div>
          <div>
            <H5SmallStrong>Contact details</H5SmallStrong>
            <div className="contactdetails">
              {communityMemberContactDetails &&
              communityMemberContactDetails.length
                ? communityMemberContactDetails.map((contactDetail, index) => (
                    <H5Big key={index}>
                      {contactDetail?.value?.indexOf("http") > -1 ? (
                        <a
                          href={contactDetail?.value}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {contactDetail?.method}
                        </a>
                      ) : contactDetail?.value?.indexOf("+971") > -1 ? (
                        <a
                          href={`tel:${contactDetail?.value}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="nounderline"
                        >
                          {contactDetail?.method} {contactDetail?.value}
                        </a>
                      ) : contactDetail?.value ? (
                        `${contactDetail?.method} ${contactDetail?.value}`
                      ) : null}
                    </H5Big>
                  ))
                : null}
            </div>
            <H5Big>
              <a
                href={`mailto:${communityMemberEmailAddress}`}
                target="_blank"
                rel="noopener noreferrer"
                className="nounderline"
              >
                {communityMemberEmailAddress}
              </a>
            </H5Big>
            <H5Big>
              <a
                href={communityMemberWebsite}
                target="_blank"
                rel="noopener noreferrer"
                className="nounderline"
              >
                {communityMemberWebsite}
              </a>
            </H5Big>
          </div>
          <div>
            <H5SmallStrong>Opening hours</H5SmallStrong>
            <H5Big>{communityMemberOpeningHours}</H5Big>
          </div>
          <div>
            <H5SmallStrong>Location</H5SmallStrong>
            {communityMemberLocation && communityMemberLocation.length
              ? communityMemberLocation.map((contactLocation, index) => (
                  <H5Big key={index}>{contactLocation}</H5Big>
                ))
              : null}
          </div>
          <p>
            <AboutSmallButton
              color={"var(--primary)"}
              backgroundColor={"transparent"}
            >
              <a
                href={
                  communityButtonLink
                    ? communityButtonLink
                    : "https://www.google.com/maps/place/Alserkal+Avenue/@25.1431157,55.2246127,15z/data=!4m2!3m1!1s0x0:0x280ffa1a1fe9224f?sa=X&ved=2ahUKEwiehr6AiPb7AhVJS2wGHdLwDxEQ_BJ6BAhiEAg"
                }
                target="_blank"
                rel="noopener noreferrer"
                className="nounderline"
              >
                {communityButtonText}
              </a>
            </AboutSmallButton>
          </p>
        </div>
      </div>
    </CommunityAboutDiv>
  );
};

export default CommunityAbout;

CommunityAbout.propTypes = {
  communityMemberContactDetails: PropTypes.arrayOf(
    PropTypes.shape({ method: PropTypes.string, value: PropTypes.string })
  ),
  communityMemberDescription: PropTypes.string,
  communityMemberEmailAddress: PropTypes.string,
  communityMemberLocation: PropTypes.arrayOf(PropTypes.string),
  communityMemberOpeningHours: PropTypes.string,
  communityMemberWebsite: PropTypes.string,
  goToMap: PropTypes.func,
};
