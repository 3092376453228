import React from "react";
import GoogleMapReact from "google-map-react";
import pin from "./images/pin.png";
const APIkey = "AIzaSyBX8Mvu6qT66fYldiFUWXfjSCBg4cASG3o";

// const APIkey = "AIzaSyCCHpoIZsIIMd8lt-3gn9JoAfO4WmgJEss"

const ImageCard = () => (
  <img src={pin} width={50} height={"auto"} style={{ width: "60px" }} />
);

const ContactUsMap = ({ center, zoom }) => (
  <div
    style={{
      height: "500px",
      width: "100%",
      marginBottom: "5px",
      opacity: 0.9,
    }}
    className="map"
  >
    <GoogleMapReact
      bootstrapURLKeys={{ key: APIkey }}
      defaultCenter={center}
      defaultZoom={zoom}
    >
      <ImageCard lat={center?.lat} lng={center?.lng} text={"Alserkal"} />
    </GoogleMapReact>
  </div>
);

export default ContactUsMap;
