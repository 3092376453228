import React from "react";
import Layout from "../components/Layout";
import { useStaticQuery, graphql } from "gatsby";
import ContactUsMap from "../components/ContactUsMap";
import CommunityAbout from "../components/CommunityAbout";
import ArticleHeroImage from "../components/ArticleHeroImage";
import { ArticleTitleWrapper, EventBody, QAFMapWrapper } from "../components/AboutStyles/elements";
import { AnimationOnScroll } from "react-animation-on-scroll";

const AboutPage = () => {
  const aboutQuery = useStaticQuery(graphql`
    {
      global: craftSiteQafSettingsGlobalSet {
        whatsAppPhone
        globalPhoneNumber
        globalEmailAddress
        siteQAFSettingWebsite
        siteQAFSettingOpeningHours
        siteQAFSettingAddress
        siteQAFSettingMapButtonLink
        siteQAFSettingMapButtonText
        siteSettingsId
      }
      craftQafAboutqafQafAboutqafEntry {
        showBannerImages
        showDescription
        showMap
        showCommunityDescription
        pageTitle
        aboutPageDescription
        faqDescription
        communityDescription
        titleSeo
        descriptionSeo
        bannerImages {
          ... on Craft_qafAssets_Asset {
            localFile {
              childImageSharp {
                fixed(quality: 100, height: 540) {
                  src
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          ... on Craft_communityAssets_Asset {
            localFile {
              childImageSharp {
                fixed(quality: 100, height: 540) {
                  src
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  `);

  const {
    whatsAppPhone,
    globalPhoneNumber,
    globalEmailAddress,
    siteQAFSettingWebsite,
    siteQAFSettingOpeningHours,
    siteQAFSettingAddress,
    siteQAFSettingMapButtonLink,
    siteQAFSettingMapButtonText,
  } = aboutQuery.global;

  const aboutPage = aboutQuery.craftQafAboutqafQafAboutqafEntry;
  const showBannerImages = aboutPage?.showBannerImages;
  const showDescription = aboutPage?.showDescription;
  const showMap = aboutPage?.showMap;
  const showCommunityDescription = aboutPage?.showCommunityDescription;
  const pageTitle = aboutPage?.pageTitle;
  const aboutPageDescription = aboutPage?.aboutPageDescription;
  const faqDescription = aboutPage?.faqDescription;
  const communityDescription = aboutPage?.communityDescription;
  const bannerImages = aboutPage?.bannerImages?.length ? aboutPage?.bannerImages?.map((image) => image?.localFile?.childImageSharp) : null;

  const titleSeo = aboutPage?.titleSeo;
  const descriptionSeo = aboutPage?.descriptionSeo;

  return (
    <Layout backgroundColor={"var(--bg-color)"} skipHeader headerBlack={true} title={titleSeo} SEODescription={descriptionSeo}>
      <ArticleTitleWrapper>
        <AnimationOnScroll animateIn="animate__zoomIn" animateOnce offset={50}>
          <h1>{pageTitle}</h1>
        </AnimationOnScroll>
      </ArticleTitleWrapper>

      {showBannerImages ? <ArticleHeroImage color={"var(--white)"} carouselImages={bannerImages} useCarousel imagesAreUrls={true} /> : null}

      {showDescription ? <EventBody dangerouslySetInnerHTML={{ __html: aboutPageDescription }} /> : null}

      {showDescription ? <EventBody style={{paddingTop: 0}} id="faq" dangerouslySetInnerHTML={{ __html: faqDescription }} /> : null}

      {showMap ? (
        <QAFMapWrapper>
          <ContactUsMap center={{ lat: 25.141861, lng: 55.226111 }} zoom={17} />
        </QAFMapWrapper>
      ) : null}

      {showCommunityDescription ? (
        <CommunityAbout
          communityMemberContactDetails={[
            { method: "Whatsapp", value: whatsAppPhone },
            { method: "Phone", value: globalPhoneNumber },
          ]}
          communityMemberDescription={communityDescription}
          communityMemberWebsite={siteQAFSettingWebsite}
          communityMemberEmailAddress={globalEmailAddress}
          communityMemberLocation={siteQAFSettingAddress.split(",")}
          communityMemberOpeningHours={siteQAFSettingOpeningHours}
          communityButtonText={siteQAFSettingMapButtonText}
          communityButtonLink={siteQAFSettingMapButtonLink}
        />
      ) : null}
    </Layout>
  );
};

export default AboutPage;
